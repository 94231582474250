import { h, render, Fragment } from 'preact';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from 'img/Bison Cash Logo.png';
import tribe_seal from 'img/tribe-seal.png';
import privacy_policy from 'docs/BSC_Privacy_Policy.pdf';
import sms_terms from 'docs/SMS Terms of Use.pdf';
import tribal_license from 'docs/Bison Bucks License.pdf';
import { useState } from 'preact/hooks';
import Modal from 'react-modal';

const navigation = [
    { name: 'SMS Terms of Use', href: sms_terms },
    { name: 'Privacy Policy', href: privacy_policy },
    { name: 'Terms of Use', href: '/terms-of-use' },
    { name: 'Rates & Terms', href: '/rates-and-terms' },
    { name: 'Data Collection', href: '/data-collection' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Lender License', href: tribal_license },
];

export default function Footer() {
    const [show_message, set_show_message] = useState(false);
    const child_modal_style = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '400px',
        },
    };
    return (
        <Disclosure as="nav" className="bg-[#6b7280] font-inter py-4">
            {({ open }) => (
                <Fragment>
                    <Modal isOpen={show_message} style={child_modal_style}>
                        <div style="text-align:center;">
                            <div style="display:flex;align-items:center;justify-content:center;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-20 stroke-logoblue-100">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                </svg>
                            </div>
                            <h2 className="text-logoblue-100 font-bold text-[20px]">You're leaving this site</h2>
                            <div style="margin-top:5px; margin-bottom:10px;">
                                <p className="text-gray-600">
                                    Press continue to proceed and open a new window. Or press close to stay on this page.
                                </p>
                            </div>
                            <div className="flex space-x-4 justify-center">
                                <button 
                                    style="border-radius:5px;"
                                    className="h-10 px-5 bg-gray-400 shadow-lg text-white hover:text-white hover:bg-gray-500"
                                    onClick={() => {
                                        set_show_message(false);
                                    }}>
                                        Close
                                </button>
                                <button
                                    style="border-radius:5px;"
                                    onClick={() => {
                                        set_show_message(false);
                                        window.open("https://onlinelendersalliance.org/look-for-the-ola-seal");
                                    }}
                                    className="h-10 px-5 bg-logoblue-100 shadow-lg text-white hover:text-white hover:bg-logoblue-200"
                                >  
                                    Continue
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <footer aria-labelledby="footer-heading">
                        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            <div className="relative flex h-16 items-center justify-between">
                                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XMarkIcon
                                                className="block h-8 w-8"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <Bars3Icon
                                                className="block h-8 w-8"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex flex-1 items-center justify-center sm:justify-around">
                                    <div className="flex flex-shrink-0 items-center">
                                        <a href="/">
                                            <img
                                                className="h-14 w-auto"
                                                src={logo}
                                                alt="Bison Cash Logo"
                                            />
                                        </a>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:block">
                                        <div className="flex space-x-4">
                                            {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="text-white hover:bg-gray-700 hover:text-green-400 rounded-md px-3 py-2 text-base font-medium"
                                                >
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className="bg-[#0d1b25] text-white hover:bg-gray-700 block rounded-md px-3 py-2 text-base font-medium"
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                        <div className="max-w-7xl mx-auto text-justify py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                            <p className="my-5 text-white">
                                BisonCash.com is a website owned and operated by
                                Bison Bucks Lending d/b/a Bison Cash ("Bison
                                Cash"), an economic development arm of,
                                instrumentality of, and wholly-owned and
                                controlled by, the Iipay Nation of Santa Ysabel
                                (the "Tribe"), a federally-recognized sovereign
                                American Indian tribe. Bison Cash adheres to
                                federal consumer finance laws and operates
                                within the boundaries of the Tribe's
                                reservation. Funds borrowed from Bison Cash are
                                governed by federal law and the laws of the
                                Tribe, not the law of the state where you
                                reside. Nothing in this communication should be
                                construed as a waiver of sovereign immunity,
                                which is expressly preserved.
                            </p>
                            <p className="my-5 text-white">
                                <span className="text-green-400">
                                    Please note:
                                </span>{' '}
                                This is an expensive form of borrowing. Bison
                                Cash installment loans are designed to assist
                                you in meeting your short-term financial needs
                                and are not intended to be a long-term financial
                                solution. Examples of why our services may be
                                used include unexpected emergencies, car repair
                                bills, medical care or essential travel expenses
                            </p>
                            <p className="my-5 text-white">
                                Completion of our installment loan application
                                in no way guarantees that you will be approved
                                for an installment loan. Bison Cash reviews your
                                information in real-time to determine whether
                                you meet our lending criteria. You acknowledge
                                that by submitting and completing the loan
                                application that you are applying for a loan. We
                                verify loan applicant information through
                                alternative credit reporting agencies and may
                                obtain your credit information in order to
                                determine your eligibility and ability to repay.
                            </p>
                            <p className="my-5 text-white">
                                This service is offered to you from the Tribe’s
                                reservation, regardless of where you may be
                                situated or access this site, and constitutes an
                                offer for consumer loans solely within the
                                Tribe’s jurisdiction, not any other state. The
                                Tribe does not allow access or accept
                                applications for this service from residents of
                                all locations, and the service may or may not be
                                available at your location. The location from
                                which the Tribe chooses to allow access to its
                                jurisdiction may change from time to time
                                without notice. If you choose to visit the
                                Tribe’s jurisdiction through the internet and
                                access services offered and regulated under the
                                Tribe’s laws, you must consent to the Tribe’s
                                jurisdiction and agree that Tribal law governs
                                such services. Availability of loans are subject
                                to change at any time at the sole discretion of
                                Bison Cash.
                            </p>
                            <p className="my-5 text-white">
                                If you have additional questions, please call us
                                at{' '}
                                <span className="text-green-400">
                                    855.501.1038
                                </span>{' '}
                                or email us at{' '}
                                <span className="text-green-400">
                                    CustomerService@BisonCash.com
                                </span>
                                .
                            </p>
                            <div className="text-center">
                                <a
                                    className="inline-block"
                                    onClick={() =>
                                        set_show_message(true)
                                    }
                                >
                                    <img src="https://stage.ola-memberseal.org/seal?seal=member&token=oBdi50lcx2DYXcIal7DX" />
                                </a>
                            </div>

                            <div className="mt-8 border-t border-white-200 pt-8 md:flex md:items-center md:justify-between">
                                <p className="mt-8 text-base text-white md:mt-0 md:order-1">
                                    &copy; 2024 Bison Cash. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </footer>
                </Fragment>
            )}
        </Disclosure>
    );
}
